import { AuthenticationQueryKeys } from 'src/types/authenticationKeys';

/**
 * Some strings are not valid URLs, so we need to handle this case.
 */
function getUrlObject(url: string) {
  try {
    return new URL(url);
  } catch (_) {
    return null;
  }
}

export function sanitiseParams(url: string): string {
  const urlObj = getUrlObject(url);

  if (!urlObj) {
    return url;
  }

  const sensitiveKeys = [
    AuthenticationQueryKeys.ResetCodeQueryKey,
    AuthenticationQueryKeys.SocialCodeKey,
    AuthenticationQueryKeys.VerifyEmailToken,
    AuthenticationQueryKeys.PasswordlessSignupCodeQueryKey,
    'token',
    'signInToken',
  ];

  sensitiveKeys.forEach((key) => urlObj.searchParams.delete(key));

  return urlObj.toString();
}
