import { EnrichmentPlugin } from '@amplitude/analytics-types';
import { isLocalEnvironment } from 'src/environment/environmentName';
import { sanitiseParams } from 'src/services/analytics/sanitiseParams';
import {
  AnalyticsProvider,
  AnalyticsProviderConfig,
} from 'src/types/analytics';
import * as amplitude from '~/analytics/amplitude.client';

/**
 * Custom enrichment plugin to filter query parameters from event properties
 * so that we don't inadvertently send sensitive information to Amplitude.
 */
const filterQueryParamsEnrichment: EnrichmentPlugin = {
  name: 'filterQueryParams',
  type: 'enrichment',
  // eslint-disable-next-line @typescript-eslint/require-await
  setup: async () => undefined,
  // eslint-disable-next-line @typescript-eslint/require-await
  execute: async (event) => {
    if (event.event_properties) {
      const eventProperties: { [key: string]: any } = event.event_properties;

      for (const key in eventProperties) {
        // ensure the key is a property of the object, not the prototype
        if (Object.prototype.hasOwnProperty.call(eventProperties, key)) {
          const value = eventProperties[key];
          if (typeof value === 'string' && value.includes('?')) {
            eventProperties[key] = sanitiseParams(value);
          }
        }
      }
    }

    return event;
  },
};

class AmplitudeAnalyticsProvider implements AnalyticsProvider {
  constructor({ apiKey, apiHost }: AnalyticsProviderConfig) {
    if (!apiKey) {
      throw new Error('Amplitude API key is missing');
    }
    try {
      amplitude.add(filterQueryParamsEnrichment);
      amplitude.init(apiKey, {
        serverUrl: apiHost,
        optOut: isLocalEnvironment(),
        defaultTracking: {
          pageViews: true,
          sessions: true,
          attribution: true,
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  public identify = (id?: string, traits?: Record<string, any>) => {
    const identifyEvent = new amplitude.Identify();

    if (traits) {
      Object.entries(traits).forEach(([key, value]) => {
        identifyEvent.set(key, value);
      });
    }

    amplitude.identify(identifyEvent, {
      user_id: id,
    });
  };

  public track = (event: string, additionalData?: Record<string, unknown>) => {
    amplitude.track(event, additionalData);
  };

  public alias = (aliasId: string) => {
    amplitude.setUserId(aliasId);
  };

  public page = (
    pageName: string,
    additionalData?: Record<string, unknown>
  ) => {
    amplitude.track(pageName, additionalData);
  };

  public reset = () => {
    amplitude.reset();
  };
}

export const getAmplitudeProvider = (config: AnalyticsProviderConfig) =>
  new AmplitudeAnalyticsProvider(config);
