import fromPairs from 'lodash/fromPairs';
import map from 'lodash/map';
import { AnalyticsProvider, DebugAnalyticsConfig } from 'src/types/analytics';
import { noop } from 'src/types/basic';

const METHODS = ['identify', 'page', 'track'];

export const debugAnalyticsProvider = ({
  logger,
  prefix,
}: DebugAnalyticsConfig): AnalyticsProvider => {
  const log = (methodName: string, ...args: any[]) => {
    if (global.SECFI_ANALYTICS === true) {
      logger(prefix, `– ${methodName} –`, ...args);
    }
  };

  const debugAnalyticsActions = fromPairs(
    map(METHODS, (methodName) => [
      methodName,
      (...args: any[]) => log(methodName, ...args),
    ])
  ) as any;

  return {
    ...debugAnalyticsActions,
    user: () => ({ anonymousId: noop() }),
  };
};
