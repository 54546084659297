import { buildApiUrl } from 'src/environment/api';
import { EnvironmentName } from 'src/environment/environmentName';
import { AnalyticsProviders } from 'src/types/analytics';

import { ApiEndpoints } from '../apiClients/endpoints';

const env = import.meta.env.VITE_DEPLOYMENT_ENV;

export function getAnalyticsConfig(): AnalyticsProviders {
  const config = {
    posthog: {
      apiKey: import.meta.env.VITE_POSTHOG_KEY || '',
      apiHost: buildApiUrl(ApiEndpoints.PostHog),
    },
    amplitude: {
      apiKey: import.meta.env.VITE_AMPLITUDE_KEY || '',
      apiHost: buildApiUrl(ApiEndpoints.Amplitude),
    },
  };

  switch (env) {
    case EnvironmentName.Production:
      return config;
    case EnvironmentName.Staging:
    case EnvironmentName.Feature:
    case EnvironmentName.Review:
      return {
        ...config,
        // eslint-disable-next-line no-console
        debug: { logger: console.debug, prefix: `[ANALYTICS]` },
      };
    default:
      return {
        // eslint-disable-next-line no-console
        debug: { logger: console.debug, prefix: `[ANALYTICS]` },
        // uncomment these individually to debug the events locally against the staging source
        // posthog: config.posthog,
        // amplitude: config.amplitude,
      };
  }
}
