import { EnvironmentName } from './environmentName';

export const customUrlKey = 'secfi_api';

const getCustomUrl = () => {
  if (typeof window !== 'undefined') {
    return sessionStorage.getItem(customUrlKey);
  }
  return null;
};

export const buildApiUrl = (url: string) => {
  if (import.meta.env.NODE_ENV === 'test') {
    return `https://test-api.secfi.dev/api${url}`;
  }

  const customUrl = getCustomUrl();

  return customUrl
    ? `${customUrl}/api${url}`
    : `${import.meta.env.VITE_SECFI_API}/api${url}`;
};

export const buildFacadeUrl = (operationName: string) => {
  if (import.meta.env.NODE_ENV === 'test') {
    return `https://test-api.secfi.dev/facade-graphql/graphql?q=${operationName}`;
  }

  const customUrl = getCustomUrl();

  return customUrl
    ? `${customUrl}/facade-graphql/graphql?q=${operationName}`
    : `${
        import.meta.env.VITE_SECFI_API
      }/facade-graphql/graphql?q=${operationName}`;
};

export const getPublicPageUrl = () => {
  switch (import.meta.env.VITE_DEPLOYMENT_ENV as EnvironmentName) {
    case EnvironmentName.Production:
      return 'https://secfi.com';
    case undefined:
      return 'http://localhost:3000';
    default:
      return 'https://secfi.app';
  }
};
