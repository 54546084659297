import posthog from 'posthog-js';
import { isLocalEnvironment } from 'src/environment/environmentName';
import { sanitiseParams } from 'src/services/analytics/sanitiseParams';
import {
  AnalyticsProvider,
  AnalyticsProviderConfig,
} from 'src/types/analytics';

class PosthogAnalyticsProvider implements AnalyticsProvider {
  constructor({ apiKey, apiHost }: AnalyticsProviderConfig) {
    if (!apiKey) {
      throw new Error('Posthog API key is missing');
    }
    try {
      posthog.init(apiKey, {
        api_host: apiHost,
        loaded: (posthog) => {
          if (isLocalEnvironment()) {
            posthog.debug();
          }
        },
        sanitize_properties: (properties) => {
          if (properties[`$current_url`]) {
            properties[`$current_url`] = sanitiseParams(
              properties[`$current_url`]
            );
          }
          if (properties[`$referrer`]) {
            properties[`$referrer`] = sanitiseParams(properties[`$referrer`]);
          }
          return properties;
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  public identify = (id?: string, traits?: Record<string, unknown>) => {
    posthog?.identify(id, traits);
  };

  public track = (event: string, additionalData?: Record<string, unknown>) => {
    posthog?.capture(event, additionalData);
  };

  public alias = (aliasId: string) => {
    posthog?.alias(aliasId);
  };

  public page = (
    pageName: string,
    additionalData?: Record<string, unknown>
  ) => {
    posthog?.capture('$pageview', {
      page: pageName,
      ...additionalData,
    });
  };

  public reset = () => {
    posthog.reset();
  };
}

export const getPosthogProvider = (config: AnalyticsProviderConfig) =>
  new PosthogAnalyticsProvider(config);
