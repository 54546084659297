import map from 'lodash/map';
import {
  AnalyticsProvider,
  AnalyticsProviderConfig,
  AnalyticsProviders,
  DebugAnalyticsConfig,
} from 'src/types/analytics';
import { extractNonNullItems } from 'src/utils/extractNonNullItems';

export interface AnalyticsProvidersMap {
  debug: (config: DebugAnalyticsConfig) => AnalyticsProvider;
  posthog: (config: AnalyticsProviderConfig) => AnalyticsProvider;
  amplitude: (config: AnalyticsProviderConfig) => AnalyticsProvider;
}

type ProviderFactory = (
  config: AnalyticsProviderConfig | DebugAnalyticsConfig | undefined
) => AnalyticsProvider;

type UpcastedProvidersMap<T extends string> = Record<
  T,
  ProviderFactory | undefined
>;

export const getAnalyticsProvidersByConfig = (
  configs: AnalyticsProviders,
  providers: AnalyticsProvidersMap
): AnalyticsProvider[] => {
  const upcastedProviders: UpcastedProvidersMap<string> = providers as UpcastedProvidersMap<
    keyof AnalyticsProvidersMap
  >;
  const analyticsproviders: AnalyticsProvider[] = extractNonNullItems(
    map(configs, (config, providerName) => {
      try {
        return upcastedProviders?.[providerName]?.(config);
      } catch (e) {
        return null;
      }
    })
  );

  return analyticsproviders;
};
