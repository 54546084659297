import attempt from 'lodash/attempt';
import fromPairs from 'lodash/fromPairs';
import map from 'lodash/map';
import { AnalyticsProvider } from 'src/types/analytics';

import { getAnalyticsConfig } from './getAnalyticsConfig';
import { getAnalyticsProvidersByConfig } from './getAnalyticsProvidersByConfig';
import { getAmplitudeProvider } from './providers/amplitude/AmplitudeAnalyticsProvider';
import { debugAnalyticsProvider } from './providers/debug/DebugAnalyticsProvider';
import { getPosthogProvider } from './providers/posthog/PosthogAnalyticsProvider';

const analyticsConfig = getAnalyticsConfig();
const analyticsProviders = getAnalyticsProvidersByConfig(analyticsConfig, {
  debug: debugAnalyticsProvider,
  posthog: getPosthogProvider,
  amplitude: getAmplitudeProvider,
});

const METHODS = ['identify', 'page', 'track', 'reset', 'user', 'alias'];

// for tests
export const AnalyticsService = (
  providers: AnalyticsProvider[]
): AnalyticsProvider => {
  const toProviders = (methodName: string) => (...args: any[]) =>
    map(providers, (provider: any) => attempt(provider[methodName], ...args));

  const analyticsService = fromPairs(
    map(METHODS, (methodName) => [methodName, toProviders(methodName)])
  );

  return analyticsService as any;
};

export const analytics = AnalyticsService(analyticsProviders);
