import { TokensResponse } from 'src/types/cognito';

import { AuthenticationServiceTokens } from './types';

export function convertCognitoTokens(
  tokens: TokensResponse
): AuthenticationServiceTokens;
export function convertCognitoTokens(
  tokens: Omit<TokensResponse, 'refresh_token'>,
  refershToken: string
): AuthenticationServiceTokens;

export function convertCognitoTokens(
  tokens: Omit<TokensResponse, 'refresh_token'> & {
    refresh_token?: string | null;
    expiration_date?: number | null;
  },
  refreshToken?: string | null
): AuthenticationServiceTokens {
  const usedRefreshToken = tokens.refresh_token ?? refreshToken;

  if (!usedRefreshToken) {
    throw new Error('refreshToken is required');
  }

  return {
    refreshToken: usedRefreshToken,
    accessToken: tokens.access_token,
    token: tokens.token,
    expiresAt: tokens.expiration_date ?? Date.now() + tokens.duration * 1000,
  };
}
