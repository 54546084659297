export enum ApiEndpoints {
  // auth
  Login = '/authentication/v2/login',
  Logout = '/authentication/v2/logout',
  GetSocialToken = '/authentication/v2/get-social-token',
  SocialAuthorize = '/authentication/v2/social-login-authorize',
  VerifyEmailToken = '/authentication/v2/verify-valid-email-token',
  RefreshToken = '/authentication/v2/refresh-token/',
  RequestResetPassword = '/authentication/v2/request-reset-password',
  DoResetPassword = '/authentication/v2/do-reset-password',
  DoChangePassword = '/authentication/v2/do-change-password/',
  User = '/authentication/v2/users/me/',
  // financing
  ExerciseProofFile = `/financing/exerciseprooffile/`,
  EquityVerification = `/financing/equityverificationfile/`,
  IdentificationFile = `/financing/identificationfile/`,
  StockAgreementFile = `/financing/stockagreementfile/`,
  ProposalFiles = '/proposalfiles/',
  SignDocument = '/docusign/signing_url/',
  TaxWithholdingsFile = `/financing/taxwithholdingsfile/`,
  // wealth
  RiskAssessmentFile = `/financing/riskassessmentfile/`,
  PayStubAccountStatementFile = `/financing/paystubaccountstatementfile/`,
  // calculations
  UsScenarios = '/calculations/usscenarios/',
  // analytics
  Research = '/research',
  PostHog = '/research/ingest-ph',
  Amplitude = '/research/ingest-am',
}
